import { INCREASE, DECREASE, SET_INIT, GET_CORGI } from './actions'

const initialState = {
    corgi: "",
    count: 0,
    mobile: null,
}

export default function count(state = initialState, action) {
    switch (action.type) {
        case INCREASE:
            return {...state, count: state.count + 1 }
        case DECREASE:
            return {...state, count: state.count - 1 }
        case SET_INIT:
            return {...state, count: action.value }
        case GET_CORGI:
            return { ...state, corgi: action.payload }
        default:
            return state
    }
}
