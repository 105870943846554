import React, {Component} from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider';

import * as Actions from '../redux/actions'
import {save, get, COUNT_ID} from '../db'

const styles = {
    paper: {
        margin: "auto",
        marginTop: 200,
        width: "40%",
        padding: 15
    },
    btnLeft: {
        marginRight: 20
    },
    img: {
        width: 100,
        marginBottom: 5,
        display: "block",
    },
    count: {
        fontWeight: 'bold',
        fontSize: 'large',
    }
}

class HomePage extends Component {
    constructor(){
        super()
        this.increase = this.increase.bind(this)
        this.decrease = this.decrease.bind(this)
        this.setInit = this.setInit.bind(this)
    }

    componentDidMount() {
        if(!this.props.corgi) {
            this.props.actions.getCorgi()
        }
        var self = this
        get(COUNT_ID).then(function (doc, err) {
            if (err && err.status === 404) {
                doc = {_id: COUNT_ID, count: 0}
            }
            console.log('get', doc.count, doc)
            // put them back
            // self.setState({count: doc.count})
            self.setInit(doc.count)
            return doc;
        })
    }

    increase(){
        save(COUNT_ID, this.props.count + 1)
        this.props.actions.increase()
    }
    decrease(){
        save(COUNT_ID, this.props.count - 1)
        this.props.actions.decrease()
    }
    setInit(value){
        this.props.actions.setInit(value)
    }

    render() {
        return (
            <Paper style={styles.paper}>
                <Typography>Dashboard</Typography>
                <Button variant="contained" color="primary" onClick={this.increase} style={styles.btnLeft}>Increase</Button>&nbsp;
                <Button variant="contained" color="primary" onClick={this.decrease} style={styles.btnLeft}>Decrease</Button>
                <br/>&nbsp;
                <Divider/>
                <Typography style={styles.count}>{this.props.count}</Typography>
            </Paper>
        )
    }
}

const mapStateToProps = (state) => ({
    count: state.count,
    corgi: state.corgi
})
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage)
