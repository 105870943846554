import PouchDB from "pouchdb";

const db = new PouchDB('test')

// export const COUNT_ID = '_local/test_count'
export const COUNT_ID = 'test_count'

export const save = (key, value) => {
    // fetch
    db.get(key).then(function (doc, err) {
        console.log(doc, err)
        if (err && err.status === 404) {
            doc = {_id: key, count: 0}
            console.log('key', key, 'doc', doc);
        } else {
            // update
            doc.count = value
            console.log('key inc', key, doc.count, doc)
        }
        // put them back
        return db.put(doc);
    }).catch((e)=>{
        console.log('catch in promise', e)
        console.log('key', key, 'new doc');
        return db.put({_id: key, count: 0});
    })
}

export const get = key => {
    return db.get(key)
}

export default {save, get, COUNT_ID}
