import React from 'react'
import { Switch, Route } from 'react-router'

import PrimarySearchAppBar from './PrimarySearchAppBar'
import AboutPage from './AboutPage'
import ContentPage from './ContentPage'
import HomePage from './HomePage'

export default function App() {
    return (
        <div>
            <PrimarySearchAppBar />
            <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/content" component={ContentPage}/>
                <Route exact path="/about" component={AboutPage}/>
            </Switch>
        </div>
    )
}
