import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from "react-router-dom";
import Provider from "react-redux/es/components/Provider";

import App from './components/App'
import configureStore from './redux/configureStore'

const state = window.__STATE__
const store = configureStore(state)

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>, document.getElementById('root'))
