import React, {Component} from 'react'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper'

const styles = {
    paper: {
        margin: "auto",
        marginTop: 200,
        width: "40%",
        padding: 15,
        textAlign: "center"
    }
}

export default class AboutPage extends Component {
    render() {
        return (
            <Paper style={styles.paper}>
                <Typography>About content 1</Typography>
            </Paper>
        )
    }
}
