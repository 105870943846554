import response from '../response'

export const INCREASE = 'INCREASE'
export const DECREASE = 'DECREASE'
export const SET_INIT = 'SET_INIT'
export const GET_CORGI = 'GET_CORGI'


export function increase() {
    return {
        type: INCREASE
    }
}
export function decrease() {
    return {
        type: DECREASE
    }
}

export function setInit(value) {
    return {
        type: SET_INIT,
        value: value
    }
}

export const getCorgi = () => async (dispatch) => {
    const result = await response(
        'https://dog.ceo/api/breed/corgi/cardigan/images/random',
    );
    dispatch({ type: GET_CORGI, payload: result.message })
};
